import React, { FC } from 'react'
import { RouteComponentProps, Router } from '@reach/router'
import './App.css'
import { VipGift } from './vipgift/VipGift'
import { VipGiftCouponApplied } from './vipgift/VipGiftCouponApplied'
import { SuperVipGiftCouponApplied } from './supervipgift/SuperVipGiftCouponApplied'
import { RockJam } from './rockjam/RockJam'
import { TunerOffer } from 'tuner/TunerOffer'
import { TunerCouponApplied } from 'tuner/TunerCouponApplied'
import { CouponApplied } from './rockjam/CouponApplied'
import { Subscribe } from './subscribe/Subscribe'
import { Welcome } from './subscribe/Welcome'
import { TranslationProvider } from 'services/localization/TranslationProvider'
import { ErrorPage } from 'ui/errors/errorPage/ErrorPage'
import { ErrorBoundary } from 'ui/ErrorBoundary'
import { SuperVipGift } from 'supervipgift/SuperVipGift'

const route = (Component: FC<any>): FC<RouteComponentProps> => props => <Component {...props} />

const SuperVipGiftRoute = route(SuperVipGift)
const SuperVipGiftCouponAppliedRoute = route(SuperVipGiftCouponApplied)
const VipGiftRoute = route(VipGift)
const VipGiftCouponAppliedRoute = route(VipGiftCouponApplied)
const RockJamRoute = route(RockJam)
const TunerOfferRoute = route(TunerOffer)
const CouponAppliedRoute = route(CouponApplied)
const TunerCouponAppliedRoute = route(TunerCouponApplied)
const SubscribeRoute = route(Subscribe)
const WelcomeRoute = route(Welcome)
const ErrorRoute = route(ErrorPage)
const PageNotFound: FC<RouteComponentProps> = () => <ErrorPage id="pageNotFound" />

export const App = () => (
  <TranslationProvider>
    <ErrorBoundary>
      <Router>
        <SuperVipGiftRoute path="/supervipgift" />
        <SuperVipGiftCouponAppliedRoute path="/supervipgiftcouponapplied/:email" />
        <VipGiftRoute path="/vipgift" />
        <VipGiftRoute path="/sweetwatergift" />
        <VipGiftCouponAppliedRoute path="/vipgiftcouponapplied/:email" />
        <RockJamRoute path="/rockjamsg" />
        <TunerOfferRoute path="/tuneroffer" />
        <CouponAppliedRoute path="/couponapplied/:email" />
        <TunerCouponAppliedRoute path="/tunercouponapplied/:email" />
        <SubscribeRoute path="/discount" />
        <WelcomeRoute path="/welcome/:email" />
        <ErrorRoute path="/error/:id" />
        <PageNotFound default />
      </Router>
    </ErrorBoundary>
  </TranslationProvider>
)
